import React from 'react';

import { graphql } from 'gatsby';
import Footer from '../components/footer';
import HeroSection from '../components/hero-section';
import Layout from '../components/layout';
import Menu from '../components/menu';
import SEO from '../components/seo';
import getTextFunctionFromData from '../utils/text';

import './privacy-policy.scss';

interface PrivacyPolicyProps {
  data: {
    django: {
      contentsForLanguage: TextFunction.titleBodyPair[]
    }
  }
}

const PrivacyPolicyPage = ({ data: { django: { contentsForLanguage } } }: PrivacyPolicyProps) => {
  const getText = getTextFunctionFromData(contentsForLanguage);
  return (
    <Layout>
      <SEO
        title={getText('SEO-title', { stringOnly: true })}
        description={getText('privacy-policy-seo-description', { stringOnly: true })}
      />
      <Menu goHome getText={getText} />
      <HeroSection getText={getText} />
      <section className="privacy-policy">
        <h2>{getText('Privacy Policy Title')}</h2>
        <p>{getText('Privacy Policy')}</p>
      </section>
      <Footer getText={getText} />
    </Layout>
  );
};

export default PrivacyPolicyPage;

export const pageQuery = graphql`
query TranslatedTermsContent($language: Int!) {
  django {
    contentsForLanguage(lang: $language) {
      title
      body
    }
  }
}
`;
